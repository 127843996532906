/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'node_modules/quill/dist/quill.bubble.css';
@import 'node_modules/quill/dist/quill.snow.css';
@import 'assets/scss/global.scss';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

.custom-calendar {
  .cal-week-view .cal-event {
    color: inherit;
  }

  .cal-week-view .cal-time-events .cal-event {
    padding: 0;
  }
}

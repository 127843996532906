* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@import './partial/card';
@import './partial/text';
@import './partial/form';
@import './partial/user-avatar-name';
@import './partial/layout';
@import './partial/table';
@import './partial/button';
@import './partial/tabset';
@import './partial/switch';
@import './partial/modal';
@import './partial/upload-file';
@import './partial/skeleton';
@import './partial/quill-editor';
@import './partial/collapsable';
@import './partial/badge';
@import './partial/menu';
@import './partial/preview-img';
@import './partial/drawer';
@import './partial/loader';
@import './partial/ngx-charts';
@import './partial/slider';
@import './partial/document-viewer';
@import './partial/input';
@import './fonts/fonts';
@import './partial/time-picker';
@import "./partial/calendar";
@import "./partial/icon";
@import "./partial/popover";
@import "./partial/pagination";

.ant-input-number-input {
  font-size: 14px;
}

nz-input-group nz-input-number {
  @apply border-0 outline-0 shadow-none #{!important};
}

nz-input-group.bg-prefix > span.ant-input-prefix {
  @apply bg-[#EDEDED] px-2 rounded-l m-[2px];
}

nz-input-group.absolute-prefix > span.ant-input-prefix {
  position: absolute;
  left: 0;
  height: 31px;
  z-index: 1;
}

nz-input-group.bg-suffix > span.ant-input-suffix {
  @apply bg-[#EDEDED] px-2 rounded-r m-[2px];
  margin: 2px 4px 2px 2px;
}

// STYLES "DISABLED" WHEN THE VIEW IS OPPORTUNITY FORM
.semi-disabled-form .ant-input[disabled],
.semi-disabled-form .ant-input-number-disabled,
.semi-disabled-form .ant-radio-disabled + span,
.semi-disabled-form .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker.ant-picker-disabled {
  @apply bg-transparent resize-none text-gray-800 #{!important};
}
.semi-disabled-form .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
  @apply border-wz4 #{!important};
}
.semi-disabled-form .ant-radio-disabled .ant-radio-inner::after {
  @apply bg-wz4 #{!important};
}
.semi-disabled-form .ant-select-disabled nz-select-arrow {
  @apply hidden;
}
.ant-picker-input > input[disabled] {
  @apply text-gray-800;
}

// VIEW ALL TEXT IN SELECTS
.ant-select-item-option-content {
  @apply whitespace-normal #{!important};
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #6063662b !important;
}

.multiple-select-one-option {
  nz-select-top-control {
    @apply h-10 flex-nowrap #{!important};
  }
  nz-select-item:nth-child(1) {
    width: calc(100% - 40px);
  }
}

/* NZ Skeleton items */
nz-skeleton-element:not([nzType="avatar"]) {
  @apply w-full p-0 rounded-lg overflow-hidden  #{!important};

  span {
    @apply w-full min-w-[100px] #{!important};
  }
}

nz-skeleton-element {
  &.skeleton-h-80 {
    span {
      @apply h-[80px] #{!important};
    }
  }
}

/* Skeleton Container */
.skeleton-container {
  @apply flex flex-col h-screen max-w-7xl xl:mx-auto;

  .header {
    @apply flex gap-4 flex-col p-8;

    nz-skeleton-element {
      span {
        @apply w-full;
      }
    }
  }

  .content {
    @apply flex-1;
  }
}

/* Details Styles */
.skeleton-details {
  .header {
    @apply p-0;
  }

  nz-skeleton-element {
    &.card {
      span {
        @apply h-[300px] #{!important};
      }
    }

    &[sidebar] {
      span {
        @apply h-[300px] #{!important};
      }
    }
  }
}

/* Table Styles */
.skeleton-table {
  .content {
    @apply flex flex-col justify-between flex-1;

    .content-main {
      @apply p-0 flex flex-col flex-1 gap-4;

      .content-header {
        @apply px-8 py-0;
        nz-skeleton-element {
          span {
            height: 120px;
          }
        }
      }

      .content-table {
        @apply gap-1 grid grid-cols-4 pt-4 px-8;
      }
    }

    .content-footer {
      @apply flex;
      nz-skeleton-element {
        @apply rounded-none #{!important};
      }
    }
  }
}

/* Menu styles */
.skeleton-menu {
  @apply w-[250px] bg-slate-50 border-r border-gray-200 p-4 flex flex-col gap-4;


  .avatar {
    @apply flex gap-4;
    .avatar-image {
      span {
        @apply w-[40px] h-[40px] #{!important};
      }
    }

    .avatar-name {
      @apply flex-1;
    }
  }

}

.user-avatar-name {
  @apply flex items-center gap-3 w-full;

  .circle-avatar {
    @apply min-w-[40px] min-h-[40px] w-10 h-10 overflow-hidden rounded-full;

    img {
      @apply w-full h-full object-cover;
    }
  }

  .user-name-alias {
    @apply flex flex-col;
    width: calc(100% - 80px);

    .name {
      @apply text-gray-800 text-sm font-semibold;
    }

    .alias {
      @apply text-gray-500 text-sm;
    }
  }
}

.ant-select-item-option .ant-avatar-string {
  transform: translateX(-50%) !important;
  left: 50% !important;
}

nz-avatar.ant-avatar span.ant-avatar-string {
  position: absolute !important;
}
nz-time-picker-panel > div.ant-picker-content > ul:nth-child(3) {
  display: none;
}
nz-time-picker-panel > div.ant-picker-footer > ul > li.ant-picker-ok > button {
  background: #000;
}


nz-time-picker-panel > div.ant-picker-content > ul:nth-child(2),
nz-time-picker-panel > div.ant-picker-footer {
  // @apply border-wz-border;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  // @apply  text-wz-black;
}

.ant-picker-footer {
  border-bottom: none;
}
nz-time-picker-panel > div.ant-picker-footer > ul > li.ant-picker-ok {
  @apply p-2;
}

nz-time-picker-panel > div.ant-picker-footer > ul > li.ant-picker-ok {
  @apply ml-0 w-full;
}
nz-time-picker-panel > div.ant-picker-footer > ul > li.ant-picker-ok > button {
  @apply w-full;
}

nz-time-picker-panel > div.ant-picker-footer > ul > li.ant-picker-now {
  @apply hidden;
}

.ant-picker-ranges {
  @apply flex justify-center;
}


// Hide seconds in time-picker
.hide-seconds nz-time-picker-panel > div.ant-picker-content > ul:nth-child(3) {
  display: none;
}


/* Icon rotation */
$icons: 33deg, 66deg, 99deg, 132deg, 165deg, 198deg, 231deg, 264deg, 297deg, 330deg, 363deg;
/* Animation duration */
$time: 8s;


/**
 * mixin to generate the animation for an icon in the loader
 * @property $initialRotation initial rotation of the icons
 * @property $animationDuration
 * @property $animationName animation name
 */
@mixin rotate-animation($initialRotation, $animationDuration, $animationName) {
  transform: rotate($initialRotation);
  i {
    transform: rotate(-$initialRotation);
    animation: zoomOut 0.8s cubic-bezier(0.25, 0.1, 0.48, 1.43) 0.4s 1 normal forwards running, $animationName $animationDuration ease 900ms infinite normal forwards running;
  }
  @keyframes #{$animationName} {
    to {
      transform: rotate(-(360deg + $initialRotation));
    }
  }
}

/**
 * Mixin to generate the animation of each icon in the loader
 * @property items icon list
 * @property $animationDuration
 */
@mixin loader-animations($items, $animationDuration) {
  @each $item in $items {
    $i: index($items, $item);
    .icon-container:nth-child(#{$i}) {
      @include rotate-animation($item, $animationDuration, icon-#{$i});
    }
  }
}

/**
 * Run the mixins for the icon animations in the loader
 */
@include loader-animations($icons, $time);

@keyframes zoomOut {
  0% {
    top: 50%;
    left: 50%;
  }
  100% {
    top: 0;
    left: 0;
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    top: 50%;
    left: 50%;
    opacity: 0;
  }
}

nz-pagination {
  @apply w-full h-[56px] md:h-[32px];
}

.ant-pagination ul, .ant-pagination ol {
  @apply flex justify-end flex-wrap;
  row-gap: 10px;
}

.ant-pagination.mini .ant-pagination-total-text {
  @apply mr-0 ml-0 mb-0 mt-0;
}

.ant-pagination.mini .ant-pagination-prev {
  @apply mr-1;
}
.ant-pagination.mini .ant-pagination-next {
  @apply ml-1;
}

//@screen md {
//  .ant-pagination ul, .ant-pagination ol {
//    @apply flex-nowrap;
//  }
//}

/*div[footer] {
  @apply relative md:z-10 bottom-0 md:sticky bg-white pt-4 md:pt-6 -mt-4 md:-mt-6 pb-4 md:pb-6 -mb-4 md:-mb-6;
}*/

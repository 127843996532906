.ql-toolbar {
  border-radius: 4px 4px 0 0;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9 !important;
  border-bottom: none !important;
}
.ql-container {
  border-radius: 0 0 4px 4px;
  border: 1px solid #d9d9d9 !important;
}

section.layout-menu {
  @apply
   max-w-7xl
  xl:mx-auto
  p-8 grid gap-4
  lg:gap-16
  lg:grid-cols-[minmax(min-content,_300px)_1fr]
  grid-rows-[min-content_1fr]
  ;
  grid-template-areas: "menu content";
  //grid-template-columns: min-content 1fr;

  .menu {
    grid-area: menu;
    @apply h-full overflow-y-auto;
  }

  .content {
    grid-area: content;
    @apply h-full overflow-y-auto;
  }

}

@media (max-width: 1023px) {
  section.layout-menu {
    grid-template-areas: "menu"
                         "content";
  }
}



.ant-table-thead > tr > th:first-child {
  @apply rounded-bl;
}
.ant-table-thead > tr > th:last-child {
  @apply rounded-br;
}
.ant-table-thead > tr > th::before {
  display: none;
}
.ant-table-thead > tr > th {
  @apply py-2.5 uppercase font-semibold text-xs text-left text-gray-700 bg-gray-50 border-none;
  border-right: none !important;
}

.ant-table-tbody > tr > td {
  @apply text-left text-gray-600 text-sm py-3;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
  @apply border-gray-200;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  @apply border-none;
}

.ant-table.ant-table-bordered > .ant-table-container {
  @apply border-none;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child {
  border-right: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  @apply bg-white;
}

.actions {
  @apply flex gap-2 items-center;
}

// [&>nz-table>nz-spin>div>div>nz-table-inner-scroll>div>table>tbody>tr>td.ant-table-ping-right .ant-table-cell-fix-right-first::after]:!shadow-none

.no-shadow-right .ant-table .ant-table-container::after {
  @apply shadow-none;
}

.ant-pagination-item a {
  @apply no-underline;
}

.ant-table-pagination.ant-pagination {
  @apply mb-0 #{!important};
}

.ant-pagination ul, .ant-pagination ol {
  @apply flex items-center;
}

.ant-table-tbody > tr > td {
  @apply p-2;
}

nz-table table tr:hover {
  td:not(.table-td-no-bg) {
    @apply bg-slate-100 cursor-pointer transition-colors #{!important};
   // @apply border-b border-l-slate-200 border-blue-500 cursor-pointer transition-colors #{!important};
  }
  td:not(.table-td-no-bg):first-child {
    //@apply border-l-2 border-b-0 border-t-0 border-blue-500 transition-colors;
  }
  //td:not(.table-td-no-bg):last-child {
  //  @apply border-r border-l-0 border-blue-500 transition-colors rounded-tr rounded-br #{!important};
  //}
  //td:not(.table-td-no-bg):last-child::before {
  //  content: '';
  //  display: block;
  //  width: 10px;
  //  height: 1.7px;
  //  background: white;
  //  position: absolute;
  //  right: -9px;
  //  top: -2px;
  //  z-index: 10;
  //  border-radius: 1px;
  //}
}

.ant-table-pagination.ant-pagination {
  @apply bg-white z-[100] bottom-0 py-4 h-max #{!important};
}

nz-table {
  .ant-pagination ul, .ant-pagination ol {
    width: 100%;
    justify-content: center;
    row-gap: 5px;
  }

  .ant-pagination ul li:first-child, .ant-pagination ol li:first-child {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-pagination ul li:last-child, .ant-pagination ol li:last-child {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@screen md {
  .ant-pagination ul li:last-child, .ant-pagination ol li:last-child {
    width: max-content !important;
    display: flex;
    justify-content: center;
  }
}

@screen lg {

  .ant-table-pagination.ant-pagination {
    @apply bg-white z-[100] bottom-0 sticky py-4 h-max #{!important};
  }

  nz-table {
    .ant-pagination ul, .ant-pagination ol {
      width: 100%;
      justify-content: flex-end;
      row-gap: 10px;
    }

    .ant-pagination ul li:first-child, .ant-pagination ol li:first-child {
      width: max-content;
      display: inline-block;
    }

    .ant-pagination ul li:last-child, .ant-pagination ol li:last-child {
      width: max-content;
      display: inline-block;
    }
  }
}




.form-group{
  @apply flex flex-col;
  /*[&>.form-input-group]:mt-2*/ // SI TE HACE FALTA UN PADDING O ALGO REVISAR AQUI
}

.form-group.show-errors {
  .form-input-group {

    .hint {
      @apply hidden;
    }

    .error {
      @apply block;
    }

  }
}



.form-input-group {
  @apply mb-4 w-full;

  label {
    @apply block mb-2 text-sm font-bold text-gray-900;
  }

  input, select, textarea, nz-input-group {
    @apply py-2;
  }

  .hint {
    @apply text-sm text-gray-600 block mt-1;
  }

  .error {
    @apply text-xs hidden text-red-500 mt-1;
  }

}

.form-input-group.show-errors {

  .hint {
    @apply hidden;
  }

  .error {
    @apply block;
  }
}

.form-input-group.required {

  label::after {
    @apply text-red-500 ml-1;
    content: '*';
  }

}

.ant-picker {
  @apply h-10;
}

// selector de tags
nz-select.tags-black:not(.spacing) .ant-select-selector {
  padding: 0 !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-large .ant-picker-input > input,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 14px;
}
nz-select.tags-black .ant-select-selector .ant-select-selection-item {
  @apply flex items-center bg-black text-white py-0.5 px-1.5 mx-0.5 #{!important};
  min-height: 22px !important;
}

nz-select.tags-black .ant-select-selector .ant-select-selection-item .ant-select-selection-item-remove {
  @apply text-white #{!important};
}

nz-input-group nz-input-number {
  @apply border-0 outline-0 shadow-none #{!important};
}

.border-option {
  .ant-select-item-option {
    @apply border-b border-gray-200;
  }
}

.ant-menu-submenu-title .menu-icon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .menu-icon + span  {
  display: inline-block;
  opacity: 0;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  @apply w-full;
}

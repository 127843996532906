$la-font-path: "../fonts" !default;

/* Comic Neue */
@font-face {
  font-family: ComicNeueBold;
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('#{$la-font-path}/Comic_Neue/ComicNeue-Bold.ttf') format('truetype');
}

.font-comic-neue-bold {
  font-family: 'ComicNeueBold', sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: ComicNeueLight;
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('#{$la-font-path}/Comic_Neue/ComicNeue-Light.ttf') format('truetype');
}

.font-comic-neue-light {
  font-family: 'ComicNeueLight', sans-serif;
  font-weight: 300;
}

@font-face {
  font-family: ComicNeue;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('#{$la-font-path}/Comic_Neue/ComicNeue-Regular.ttf') format('truetype');
}

.font-comic-neue {
  font-family: 'ComicNeue', sans-serif;
  font-weight: 400;
}
/* Comic Neue */

/* Oxygen */
@font-face {
  font-family: OxygenBold;
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('#{$la-font-path}/Oxygen/Oxygen-Bold.ttf') format('truetype');
}

.font-oxygen-bold {
  font-family: 'OxygenBold', sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: OxygenLight;
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('#{$la-font-path}/Oxygen/Oxygen-Light.ttf') format('truetype');
}

.font-oxygen-light {
  font-family: 'OxygenLight', sans-serif;
  font-weight: 300;
}

@font-face {
  font-family: Oxygen;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('#{$la-font-path}/Oxygen/Oxygen-Regular.ttf') format('truetype');
}

.font-oxygen {
  font-family: 'Oxygen', sans-serif;
  font-weight: 400;
}
/* Oxygen */

/* Roboto */
@font-face {
  font-family: RobotoBold;
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('#{$la-font-path}/Roboto/Roboto-Bold.ttf') format('truetype');
}

.font-roboto-bold {
  font-family: 'RobotoBold', sans-serif;
}

@font-face {
  font-family: RobotoLight;
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('#{$la-font-path}/Roboto/Roboto-Light.ttf') format('truetype');
}

.font-roboto-light {
  font-family: 'RobotoLight', sans-serif;
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('#{$la-font-path}/Roboto/Roboto-Regular.ttf') format('truetype');
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

/* Roboto */

/* Ubuntu */
@font-face {
  font-family: UbuntuBold;
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('#{$la-font-path}/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

.font-ubuntu-bold {
  font-family: 'UbuntuBold', sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: UbuntuLight;
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('#{$la-font-path}/Ubuntu/Ubuntu-Light.ttf') format('truetype');
}

.font-ubuntu-light {
  font-family: 'UbuntuLight', sans-serif;
  font-weight: 300;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('#{$la-font-path}/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

.font-ubuntu {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
}
/* Ubuntu */

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  // @apply flex flex-row-reverse items-center;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
    @apply p-0;
    & > div {
      @apply flex relative top-[3px];
    }
}

// ngx-charts-bar-horizontal
.charts-bar-horizontal.legend-position-below .legend-labels {
  @apply grid gap-2 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 p-4 mt-4;
}

.chart-legend {
  @apply block #{!important};
}

.legend-labels {
  @apply flex flex-wrap gap-x-2 gap-y-0.5 bg-gray-50 #{!important};
}
// - ngx-charts-bar-horizontal

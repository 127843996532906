.anticon {
  vertical-align: 1px!important;
}
.ant-select-item-option .ant-avatar-string {
  position: absolute;
  transform-origin: 0 center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
}

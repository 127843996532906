

button.ant-btn {
  @apply font-bold;
  &>* {
    text-shadow: none;
  }

  &[nztype="primary"].black {
    @apply flex bg-black/10 border-transparent hover:bg-black/5 items-center shadow-none;
    span, i {
      @apply text-black #{!important};
    }
  }

  &[nzDanger], &[nzDanger]:active, &[nzDanger]:focus {
    @apply flex bg-wz2/10 border-transparent hover:bg-wz2/5 hover:border-transparent items-center shadow-none;
    span, i {
      @apply text-wz2 #{!important};
    }
  }

  &.btn-wz1 {
    @apply flex bg-wz1/10 border-transparent hover:bg-wz1/5 hover:border-transparent items-center shadow-none;
    span, i {
      @apply text-wz1 #{!important};
    }
  }

  &.btn-wz2 {
    @apply flex bg-wz2/10 border-transparent hover:bg-wz2/5 hover:border-transparent items-center shadow-none;
    span, i {
      @apply text-wz2 #{!important};
    }
  }

  &.btn-wz3 {
    @apply flex bg-wz3/10 border-transparent hover:bg-wz3/5 hover:border-transparent items-center shadow-none;
    span, i {
      @apply text-wz3 #{!important};
    }
  }

  &.btn-wz4 {
    @apply flex bg-wz4/10 border-transparent hover:bg-wz4/5 hover:border-transparent items-center shadow-none;
    span, i {
      @apply text-wz4 #{!important};
    }
  }

  &.btn-purple {
    @apply flex bg-[#686de0]/10 border-transparent hover:bg-[#686de0]/5 hover:border-transparent items-center shadow-none;
    span, i {
      @apply text-[#686de0] #{!important};
    }
  }

  &.btn-green {
    @apply flex bg-[#4CAF50]/10 border-transparent hover:bg-[#4CAF50]/5 hover:border-transparent items-center shadow-none;
    span, i {
      @apply text-[#4CAF50] #{!important};
    }
  }

  & > i {
    text-shadow: none;
  }
  &[nztype="text"].back {
    @apply flex bg-black/[0.018] border-transparent items-center shadow-none;
    span, i {
      @apply text-black/[0.85] #{!important};
    }
  }
}
